<template>
  <div>
    <div v-if="$hasPermissions(clientSession, ['CLIENT_REPORTS'], 1)">
      <vue-headful :title="pageTitle" />
      <div class="has-text-centered has-background-primary" style="margin-bottom: 20px;">
        <h1 class="is-size-6 has-text-white" style="padding: 5px 0px"
        >{{ pageheading.toLocaleUpperCase() }}</h1>
      </div>
      <div style="max-width: 95%; margin: auto;">

        <div id="body-content-area">
          <div :class="{ 'hidden': !isLoading }">
            <Loading />
          </div>
          <div :class="{ 'visible': isLoading }">
            <div class="columns top-bar">
            <div id="dateRangeSelector" class="column" style="display: flex; flex-direction: row;">
              <div>
                <label>Report Selection Period:</label>&nbsp;&nbsp;<span v-if="hideDateLabel" style="font-size: .8em; color: #808080">({{ dates.currentDateRange.start | formatDate }} to {{dates.currentDateRange.end | formatDate}})</span>
                <br />
                <kendo-dropdownlist id="ddlReportDateRange" name="ddlReportDateRange"
                                    v-model="selectedReportDateRange"
                                    :data-source="reportDateRanges"
                                    :data-text-field="'text'"
                                    :data-value-field="'value'"
                                    @select="onReportDateRangeSelect">
                </kendo-dropdownlist>
              </div>

              <div id="dateRangePicker" v-show="selectedReportDateRange === '3'">
                <div style="display: inline-flex">
                  <div style="margin: 0 20px;">
                    <label for="reportStartDate">Start</label> <br />
                    <kendo-datepicker id="reportStartDate"
                                      name="reportStartDate"
                                      style="width: 200px"
                                      required="required"
                                      v-model="dates.currentDateRange.start"
                                      :min="dates.start.minDate"
                                      :max="dates.start.maxDate"
                                      :format="'yyyy-MM-dd'"
                                      v-on:change="onStartDateRangeChange">
                    </kendo-datepicker>
                    <span data-for='reportStartDate' class='k-invalid-msg'></span>
                  </div>
                  <div>
                    <label for="reportEndDate">End</label><br />
                    <kendo-datepicker id="reportEndDate"
                                      name="reportEndDate"
                                      style="width: 200px"
                                      required="required"
                                      v-model="dates.currentDateRange.end"
                                      :max="dates.end.maxDate"
                                      :format="'yyyy-MM-dd'"
                                      v-on:change="onEndDateRangeChange">
                    </kendo-datepicker>
                    <span data-for='reportEndDate' class='k-invalid-msg'></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="top-bar-spacer" style="height: 8px; width: 100%;">&nbsp;</div>

          <div>

            <div style="float: right; margin-top: -4.3rem;" class="control grid-button">
              <div id="buttongroup">
              <div class="level">
                <div class="level-left">
                  <div class="field is-grouped">
                    <div class="control">
                      <button class="button is-accent" :disabled="!$hasPermissions(clientSession, ['CLIENT_REPORTS'], 2)" @click="$router.push({ name: 'NewReport', params: {  }})">
                        <span class="icon">
                          <i class="fal fa-plus-square"></i>
                        </span>
                        <span>
                          Add Report
                        </span>
                      </button>
                    </div>
                    <div class="control">
                      <button  class="button is-dark"
                          @click="confirmDelete"
                          :disabled="!hasSelection || !$hasPermissions(clientSession, ['CLIENT_REPORTS'], 2)">
                        <span class="icon">
                          <i class="fal fa-trash-alt"></i>
                        </span>
                        <span>Delete Report{{ hasPluralSelection ? 's' : '' }}</span>
                      </button>
                    </div>
                    <div class="control">
                      <button class="button is-light" @click="clearFilters">
                        <span>Clear Filters</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </div>
            <div ref="divWrapper">
              <Grid
              v-if="maxHeight > 0"
              :style="{height: maxHeight + 'px'}"
              :filter="filter"
              :data-items="institutionReports"
              :selected-field="selectedField"
              :sortable="true"
              :sort="sort"
              :filterable="true"
              :pageable="{
                ...pageable,
                pageSizes: pageSizes
              }"
              :page-size="pageSize"
              :skip="skip"
              :take="take"
              :total="totalRecords"
              :columns="columns"
              @pagechange="pageChangeHandler"
              @sortchange="sortChangeHandler"
              @filterchange="filterChangeHandler"
              @selectionchange="onSelectionChange"
              @headerselectionchange="onHeaderSelectionChange"
            >
              <template v-slot:editTemplate="{props}">
                <td>
                  <router-link class="bcc-color" :to="{ name: 'EditReport', params: { id: `${props.dataItem.id}`, model: `${JSON.stringify(props.dataItem)}`, skip: `${skip}` }}">
                    {{props.dataItem.displayName}}
                  </router-link>
                </td>
              </template>
              <template v-slot:downloadTemplate="{props}">
                <td class="has-text-centered">
                  <a class="download" :href="`${props.dataItem.path}`">
                    <i class="fas fa-download"></i>
                  </a>
                  &nbsp;
                  <a class="download" :href="'#'" @click.prevent="viewReport(props.dataItem)">
                    <i class="fas fa-eye"></i>
                  </a>
                </td>
              </template>
              <template v-slot:reportTypeUploadDateTemplate="{ props }">
                <td>
                  {{ getUploadDate(props.dataItem.uploadDate).format('MM/DD/YYYY') }}
                </td>
              </template>
              <template v-slot:reportYearOverrideCell="{ props }">
                <td>
                  {{ parseYearOverride(props.dataItem.yearOverride) }}
                </td>
              </template>
              <template v-slot:reportTypeFilterSlotTemplate="{props, methods}">
                <div class="k-filtercell">
                  <div class="k-filtercell-wrapper">
                    <input type="text" class="k-textbox" :id="`${props.field}`" :value="props.value" @input="(ev) => {methods.change({operator: 'contains', field: props.field, value: ev.target.value, syntheticEvent: ev});}">
                  </div>
                </div>
              </template>
            </Grid>
            </div>
          </div>

          <div id="delete-modal" class="modal is-active" v-if="showDeleteModal">
              <div class="modal-background" @click="showDeleteModal = false"></div>
              <div class="modal-card">
                <header class="modal-card-head has-bg-danger">
                  <div class="modal-card-title has-bg-danger">Confirm Delete</div>
                  <a class="delete" aria-label="close" @click="showDeleteModal = false"></a>
                </header>
                <section class="modal-card-body">
                  <div class="content">
                    Type "<b>delete</b>" to confirm that you want to delete one or more items.
                  </div>
                  <div class="field">
                    <div class="control">
                      <input type="text" class="input" placeholder="type `delete` and then click confirm" v-focus
                        v-model.trim="confirmationText" @keydown.enter.prevent="deleteIfConfirmed" />
                    </div>
                  </div>
                </section>
                <footer class="modal-card-foot">
                  <a class="button is-danger" @click="deleteIfConfirmed" :disabled="confirmationText !== 'delete'">
                    <span class="icon">
                      <i class="fal fa-trash-alt"></i>
                    </span>
                    <span>Confirm</span>
                  </a>
                  <a class="button" @click="showDeleteModal = false">Cancel</a>
                </footer>
              </div>
            </div>
        </div>
      </div>
    </div>
    </div>
    <div v-else>
      <vue-headful :title="pageTitle" />
      <div class="has-text-centered has-background-primary" style="margin-bottom: 20px;">
        <h1 class="is-size-6 has-text-white" style="padding: 5px 0px"
        >No Permission</h1>
      </div>

      <center>
        <b>You Lack The Permissions Required To View This Page</b>
      </center>
    </div>
  </div>
</template>

<script>
//* ***** this is grid native example (there is a wrapper version) *******
import Vue from 'vue'
import Loading from './Loading'
import { mapState } from 'vuex'
import { activeSite } from '../vuex-actions'
import { orderBy, filterBy } from '@progress/kendo-data-query'
import { Grid } from '@progress/kendo-vue-grid'
import { DatePicker } from '@progress/kendo-dateinputs-vue-wrapper'
import { DropDownList } from '@progress/kendo-dropdowns-vue-wrapper'
import moment from 'moment'

let pageName = 'Report Management'

Vue.filter('formatDate', function (date) {
  if (date) {
    return moment(new Date(date).toISOString().substring(0, 10)).format('MMM D, YYYY')
  }
})

// https://stackoverflow.com/a/1909508
function debounce (fn, ms) {
  let timer = 0
  return function (...args) {
    clearTimeout(timer)
    timer = setTimeout(fn.bind(this, ...args), ms || 0)
  }
}

export default {
  components: {
    Loading,
    'Grid': Grid,
    'kendo-datepicker': DatePicker,
    'kendo-dropdownlist': DropDownList
  },
  data () {
    return {
      maxHeight: 0,
      minResults: 0,
      dates: {
        start: {
          minDate: new Date(1969, 0, 1),
          maxDate: new Date(moment().toDate())
        },
        end: {
          minDate: new Date(moment().toDate()),
          maxDate: new Date(moment().toDate())
        },
        currentDateRange: { start: new Date(moment().year(), 0, 1), end: new Date(moment().toDate()) }
      },
      reportDateRanges: [
        { text: 'Current Year', value: '1' },
        { text: 'Last Year', value: '2' },
        { text: 'Custom Date Range', value: '3' },
        { text: 'All', value: '4' }
      ],
      selectedReportDateRange: '1',
      props: {
        grid: Grid,
        field: String,
        filterType: String,
        value: [String, Number, Boolean, Date],
        operator: String
      },
      skip: 0,
      take: 20,
      pageSize: 20,
      page: 1,
      totalRecords: 0,
      pageable: {
        buttonCount: 5,
        info: true,
        type: 'numeric',
        pageSizes: true,
        previousNext: true
      },
      payload:
      {
        filter:
        {
          allReports: false,
          institutionId: 0,
          dateFilter:
          {
            startDate: null,
            endDate: null
          }
        },
        limit: 20,
        offset: 0
      },
      staticColumns: [
        {
          field: 'id',
          title: 'Id',
          filterable: false,
          hidden: true
        },
        {
          field: 'displayName',
          title: 'Report Name',
          width: '320px',
          cell: 'editTemplate',
          filterCell: 'reportTypeFilterSlotTemplate'
        },
        {
          field: 'typeCode',
          filterable: false,
          hidden: true,
          width: 100
        },
        {
          field: 'typeCode',
          title: 'Report Type',
          filterable: true,
          filterCell: 'reportTypeFilterSlotTemplate',
          width: 120
        },
        {
          field: 'institutionId',
          filterable: false,
          hidden: true
        },
        {
          field: 'institutionName',
          title: 'Institution',
          filterable: true,
          filterCell: 'reportTypeFilterSlotTemplate'
        },
        {
          field: 'uploadUserId',
          filterable: false,
          hidden: true
        },
        {
          field: 'uploadUserName',
          title: 'User',
          filterable: true,
          filterCell: 'reportTypeFilterSlotTemplate',
          width: 200
        },
        {
          field: 'uploadDate',
          title: 'Upload Date',
          width: '140px',
          cell: 'reportTypeUploadDateTemplate',
          filterCell: 'reportTypeFilterSlotTemplate',
          filterable: false
        },
        {
          field: 'YearOverride',
          title: 'Year Override',
          width: '200px',
          cell: 'reportYearOverrideCell',
          filterCell: 'reportTypeFilterSlotTemplate',
          filterable: true
        },
        {
          field: 'path',
          title: 'Actions',
          filterable: false,
          sortable: false,
          width: '110px',
          cell: 'downloadTemplate'
        }
      ],
      input: {
        name: '',
        reportType: '',
        user: '',
        institution: '',
        startDate: '',
        endDate: ''
      },
      reports: [],
      confirmationText: '',
      isLoading: true,
      showDeleteModal: false,
      selectedField: 'selected',
      selectedRows: [],
      pageheading: pageName,
      sort: [
        { field: 'uploadDate', dir: 'asc' }
      ],
      filter: {
        logic: 'and',
        filters: []
      }
    }
  },
  watch: {
    async $route (to, from) {
      let reload = await this.loadQueryParams(to.query)
      if (reload) {
        await this.goToPage(to.query.page, this.getReports)
      }
    }
  },
  computed: {
    ...mapState([activeSite, 'clientSession']),
    pageTitle () {
      return pageName + ' - ' + this.activeSite.displayName
    },
    lastpage () {
      return Math.ceil(this.totalRecords / this.perPage)
    },
    hasSelection () {
      return this.selectedRows.length > 0
    },
    hasPluralSelection () {
      return this.selectedRows.length > 1
    },
    gridWidth () {
      return this.$refs.divWrapper.offsetWidth
    },
    institutionReports: function () {
      if (this.reports.length === 0) { return [] }

      return orderBy(filterBy(this.reports, this.filter), this.sort)
    },
    areAllSelected () {
      let selected = this.reports.findIndex(item => item.selected === false)
      let isUndefined = this.reports.findIndex(item => item.selected === undefined)

      if (this.reports.length === 0) { return false }
      return selected === -1 && isUndefined === -1
    },
    columns () {
      return [
        { field: 'selected', width: '28px', filterable: false, headerSelectionValue: this.areAllSelected },
        ...this.staticColumns
      ]
    },
    hideDateLabel () {
      return this.selectedReportDateRange !== '3' && this.selectedReportDateRange !== '4'
    },
    pageSizes () {
      let sizes = [this.minResults]
      let max = this.totalRecords
      let counter = this.minResults
      let min = this.minResults

      if (max !== 0 && min !== 0) {
        while (counter < max) {
          let size = counter + 10
          sizes.push(size > max ? max : size)
          counter += 10
        }
      }

      return sizes
    }
  },
  mounted () {
    let that = this
    this.$nextTick(() => {
      let elements = [
        '.has-text-centered.has-background-primary',
        '.columns.top-bar',
        '.top-bar-spacer',
        '.bottom-bar',
        '.bottom-bar-spacer'
      ]

      let func = async (results) => {
        let [numberOfResults, maxHeight] = results

        if (this.minResults && this.maxHeight && (numberOfResults === null || maxHeight === null)) {
          return
        }

        this.take = numberOfResults
        this.perPage = numberOfResults
        this.minResults = numberOfResults
        this.maxHeight = maxHeight

        await this.getSearchQuery()
        await this.checkPage(this.getReports)
        this.getReports(true)
      }

      that.getListHeights('body-content-area', 40, 79, elements, func.bind(this))

      window.addEventListener('resize', () => {
        that.getListHeights('body-content-area', 40, 79, elements, func.bind(that))
      })
    })
  },
  async created () {
    if (localStorage.reportSkip) {
      this.skip = JSON.parse(localStorage.getItem('reportSkip'))
      localStorage.removeItem('reportSkip')
    }

    if (localStorage.reportFilters) { this.filters = JSON.parse(localStorage.getItem('reportFilters')) }

    if (localStorage.reportSort) { this.sort = JSON.parse(localStorage.getItem('reportSort')) }

    if (localStorage.reportSelection) { await this.loadQueryParams(JSON.parse(localStorage.getItem('reportSelection'))) }

    if (localStorage.reportPageSize) {
      let pageSize = JSON.parse(localStorage.getItem('reportPageSize'))
      this.take = pageSize
      this.perPage = pageSize
      this.pageSize = pageSize
    }
  },
  updated () {
    // Manually repopulate filter values after server-side querying
    if (this.filters) {
      this.filters.forEach(element => {
        var filterInput = document.getElementById(element.field)
        if (filterInput) { filterInput.value = element.value }
      })
    }
  },
  methods: {
    setGridWidth (percentage) {
      return Math.round(this.gridWidth / 100) * percentage
    },
    viewReport (report) {
      this.$router.push({ name: 'ViewReport', params: { id: report.id, 'report': report } })
    },
    getCurrentDateRange: function () {
      const dateObj = new Date()
      const month = dateObj.getUTCMonth()
      const day = dateObj.getUTCDate()
      const year = dateObj.getUTCFullYear()
      const start = new Date(year, 0, 1)
      const end = new Date(year, month, day)

      return {
        start: start,
        end: end
      }
    },
    getLastYearDateRange: function () {
      const lastYear = (new Date().getUTCFullYear() - 1)
      const start = moment(new Date(lastYear, 0, 1))// .hours(11).minutes(59).seconds(59).milliseconds(0)
      const end = moment(new Date(lastYear, 11, 31))// .hours(11).minutes(59).seconds(59).milliseconds(0)

      return {
        start: start.toDate(),
        end: end.toDate()
      }
    },
    isCurrentDateRange: function () {
      if (!this.dates.currentDateRange) { return false }
      const range = this.getCurrentDateRange()
      if (!range) { return false }

      return this.dates.currentDateRange.start === range.start && this.dates.currentDateRange.end === range.end
    },
    async applyFilters () {
      this.goToPage(1, this.getReports)
    },
    async refreshData () {
      await this.getReports()
    },
    loadQueryParams (query) {
      if (query.selectedReportDateRange) {
        this.selectedReportDateRange = query.selectedReportDateRange
        // Reset paging when querystring values present since individual page contents cannot be guaranteed
        this.page = 1
        this.perPage = 20
      }

      if (query.dates && query.dates.start) { this.dates.currentDateRange.start = new Date(query.dates.start).toISOString() }

      if (query.dates && query.dates.end) { this.dates.currentDateRange.end = new Date(query.dates.end).toISOString() }

      return false
    },
    async getSearchQuery () {
      this.payload.filter.allReports = this.selectedReportDateRange === '4'
      this.payload.filter.dateFilter.startDate = new Date(this.dates.currentDateRange.start)
      this.payload.filter.dateFilter.endDate = new Date(this.dates.currentDateRange.end)

      // set default sort
      this.payload.sort = 'uploadDate ASC'
      this.payload.limit = this.perPage || 20
      this.payload.offset = this.skip || 0

      if (this.filters) { this.payload.filter.gridFilters = this.filters } else { this.payload.filter.gridFilters = [] }

      if (this.sort.length) { this.payload.sort = this.sort[0].field + ' ' + this.sort[0].dir }
    },
    async getReports (isInitialLoad = true) {
      try {
        if (isInitialLoad) { this.isLoading = true }

        let route = `/reports/search/`
        await this.getSearchQuery()

        let response = {}
        response = await this.axios.post(route, this.payload)
        this.totalRecords = response.data.page.totalRecords
        this.page = response.data.page.pagep

        let filteredReports = filterBy(response.data.records, this.filter)
        if (filteredReports) { filteredReports = filteredReports.map(item => { return { ...item, selected: false } }) }
        this.reports = orderBy(filteredReports, this.sort)
      } catch (error) {
        this.handleApiErr(error)
      } finally {
        this.isLoading = false
      }
    },
    deselectAll () {
      window.requestAnimationFrame(() => {
        this.selectedRows = []
        this.reports.forEach((item) => {
          item.selected = false
        })

        window.requestAnimationFrame(() => {
          this.$forceUpdate()

          this.getReports(true)
        })
      })
    },
    deleteIfConfirmed (event, data) {
      if (this.confirmationText === 'delete') {
        this.deleteSelected('/reports', null, null, () => {
          this.reports = []
          this.selectedRows = []

          this.deselectAll()
        })
      }
    },
    onHeaderSelectionChange (event) {
      let checked = event.event.target.checked
      Vue.set(this, 'reports', this.reports.map((item) => { return { ...item, selected: checked } }))

      if (checked) {
        this.selectedRows = this.institutionReports
          .filter(function (e) {
            return e.selected === true
          })
          .map(function (i) {
            return i.id
          })
      } else {
        this.selectedRows = []
      }
    },
    onSelectionChange (event) {
      let checked = event.event.target.checked
      const id = event.dataItem.id
      if (checked) {
        this.selectedRows.push(id)
      } else {
        var idx = this.selectedRows.indexOf(id)
        if (idx > -1) {
          this.selectedRows.splice(idx, 1)
        }
      }
      Vue.set(event.dataItem, this.selectedField, event.dataItem[this.selectedField])
    },
    stripDate: function (dateString) {
      // 2016-02-24T00:11:00Z to 2016-02-24
      // Note this is needed to prevent off-by-one date errors due to the timestamp and timezone offset
      return dateString.substring(0, 10)
    },
    getMapValue (data, key) {
      if (typeof data === 'undefined' || typeof key === 'undefined') { return }

      if (key === '') { return }

      try {
        if (data.length === 0) { return key }

        let value = data.get(key)

        if (typeof value === 'undefined') { return key }

        return value
      } catch (e) {
        this.handleApiErr(e)
      }
    },
    getShortDate: function (h, tdElement, props, listeners) {
      return h('td', {
        on: {
          click: function (e) {
          }
        }
      }, [this.stripDate(props.dataItem.uploadDate)])
    },
    pageChangeHandler: function (event) {
      this.skip = event.page.skip
      this.take = event.page.take
      this.perPage = event.page.take
      this.pageSize = event.page.take

      localStorage.setItem('reportPageSize', event.page.take)
      this.getReports(false)
    },
    updatePagerState: function (key, value) {
      const newPageableState = Object.assign({}, this.pageable, { [key]: value })
      this.pageable = newPageableState
    },
    changeHandler: function (type, value) {
      this.updatePagerState(type, value)
    },
    sortChangeHandler: function (e) {
      this.sort = e.sort
      localStorage.setItem('reportSort', JSON.stringify(this.sort))
      this.getReports(false)
    },
    change: function (filter) {
      if (filter.syntheticEvent) { this.filter = filter.syntheticEvent.filter }
    },
    filterChangeHandler: debounce(function (e) {
      if (e.filter && !this.filters) {
        this.filters = e.filter.filters
      } else if (e.filter && this.filters) {
        e.filter.filters.forEach(element => {
          this.filters.replaceOrPush(element, function (e) {
            return e.field === element.field
          })
        })
      } else {
        if (e.event.value === '') { this.filters = this.filters.filter(x => x.field !== e.event.field) }
      }

      localStorage.setItem('reportFilters', JSON.stringify(this.filters))

      this.skip = 0
      this.getReports(false)
    }, 500),
    filterRender: function (h, defaultRendering, props, change) {
      return defaultRendering
    },
    clearFilters: function () {
      // Manually clear filter values
      if (this.filters) {
        this.filters.forEach(element => {
          var filterInput = document.getElementById(element.field)
          if (filterInput) { filterInput.value = '' }
        })

        this.filters = []
      }

      this.skip = 0
      this.sort = [
        { field: 'uploadDate', dir: 'asc' }
      ]

      localStorage.removeItem('reportFilters')
      localStorage.removeItem('reportSort')

      this.getReports(false)
    },
    setCurrentDateRange: function () {
      try {
        const range = this.getCurrentDateRange()
        if (range) {
          this.dates.maxDate = range.end
          this.dates.currentDateRange = range
        }
      } catch (e) {
        this.handleApiErr(e)
      }
    },
    onStartDateRangeChange: function (e) {
      this.dates.currentDateRange.start = new Date(e.sender.value())

      if (this.dates.currentDateRange.start && this.dates.currentDateRange.end) {
        if (moment(this.dates.currentDateRange.start).isAfter(this.dates.currentDateRange.end)) {
          // TODO WL add visual cues
          return false
        }

        let queryParams = {
          selectedReportDateRange: this.selectedReportDateRange,
          dates: this.dates.currentDateRange
        }
        localStorage.setItem('reportSelection', JSON.stringify(queryParams))

        this.getReports()
        this.$router.push({ query: Object.assign({}, this.$route.query, { startDate: this.stripDate(this.dates.currentDateRange.start.toISOString()) }) })

        return false
      }
    },
    onEndDateRangeChange: function (e) {
      this.dates.currentDateRange.end = new Date(e.sender.value())

      if (this.dates.currentDateRange.start && this.dates.currentDateRange.end) {
        if (moment(this.dates.currentDateRange.end).isBefore(this.dates.currentDateRange.start)) {
          // TODO WL add visual cues
          return false
        }

        let queryParams = {
          selectedReportDateRange: this.selectedReportDateRange,
          dates: this.dates.currentDateRange
        }
        localStorage.setItem('reportSelection', JSON.stringify(queryParams))

        this.getReports()
        this.$router.push({ query: Object.assign({}, this.$route.query, { endDate: this.stripDate(this.dates.currentDateRange.end.toISOString()) }) })

        return false
      }
    },
    onReportDateRangeSelect: async function (e) {
      const selectedOptionValue = e.dataItem.value
      this.payload.filter.allReports = false
      this.skip = 0
      try {
        this.selectedReportDateRange = selectedOptionValue
        let range = {}

        if (selectedOptionValue === '3' || selectedOptionValue === '4') {
          this.dates.currentDateRange.start = null
          this.dates.currentDateRange.end = null
          if (selectedOptionValue === '4') {
            this.payload.filter.allReports = true
            this.$router.push({ query: Object.assign({}, this.$route.query, { startDate: undefined }) })
            this.$router.push({ query: Object.assign({}, this.$route.query, { endDate: undefined }) })
            await this.getReports()
          }
        } else {
          if (selectedOptionValue === '1') { range = this.getCurrentDateRange() }
          if (selectedOptionValue === '2') { range = this.getLastYearDateRange() }

          if (range) {
            this.dates.currentDateRange = range
            this.$router.push({ query: Object.assign({}, this.$route.query, { startDate: this.stripDate(range.start.toISOString()) }) })
            this.$router.push({ query: Object.assign({}, this.$route.query, { endDate: this.stripDate(range.end.toISOString()) }) })
            await this.getReports()
          }
        }

        let queryParams = {
          selectedReportDateRange: this.selectedReportDateRange,
          dates: this.dates.currentDateRange
        }
        localStorage.setItem('reportSelection', JSON.stringify(queryParams))

        this.$router.push({ query: Object.assign({}, this.$route.query, { selectedReportDateRange: selectedOptionValue }) })
      } catch (e) {
        this.handleApiErr(e)
      }
    }
  }
}
</script>

<style>
.k-dropdown {
  width: auto!important;
  min-width: 200px;
}

#body-content-area {
  position: absolute;
  top: 35px;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px;
  overflow-y: auto;
}
.k-widget k-dateinput k-state-default k-label{
  font-weight: 600 !important;
  padding: 8px;
}
.k-checkbox:checked{
  background-color:  #7fb942;
  color: white;
  border-color: #7fb942;
}
.k-pager-numbers .k-state-selected{
  background-color:  #7fb942;
  color: white;
  border-color: #7fb942;
}
.k-grid th{
  font-weight: 600 !important;
}
.k-grid td.k-state-selected, .k-grid tr.k-state-selected > td{
  background-color: #CBE3B3;
  opacity: 0.8;
  font-weight: 600;
}
.k-list .k-item.k-state-selected{
   background-color:  #7fb942;
}
.k-list .k-item.k-state-selected:hover{
  background-color: #CBE3B3;
  color: #0d0c0c
}
.k-date-picker{
  width: 90%;
  margin-right: 6px;
  border: pink;
}
a.download, a.bcc-color{
  color: #7fb942 !important;
}
.page-subtitle{
  color: #7fb942;
  font-weight: 600;
  font-size: 1em;
  margin-bottom: 8px;
}
.reportDateStatus, .status {
    margin-top: 10px;
}
.k-invalid-msg {
    display: inline-block;
}
.invalid {
    color: red;
}
.valid {
    color: green;
}
.bcc-color{
   color: #7fb942;
}
.grid-button .button {
  margin-bottom: 5px;
}
.k-grid td:first-child {
  color: transparent!important;
}

.k-grid th:first-child {
  color: transparent!important;
}

.hidden {
  display: none;
}

.visible {
  visibility: none;
}

.k-master-row > td:first-child {
  color: transparent!important;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
